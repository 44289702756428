//import { ids } from "webpack";
let start = 0;
// const dietScoreElem = document.getElementById("dietScore");
const conicGradient = document.getElementById("conicGradient");
const dietPercent = document.getElementById("dietPercent");

const arrID = [
	1003, 1004, 1005, 1008, 1051, 1087, 1089, 1090, 1091, 1092, 1093, 1095, 1098,
	1103, 1106, 1165, 1166, 1167, 1175, 1190, 1178, 1162, 1109, 1185,
];

let progress;

export default (res, IDs) => {
	
	let input = 0;
	let percent = res.Percent
	let percentValue = 100 / IDs.length;

	IDs.forEach(ID => {
		input += percent[ID] * percentValue / 100
		// Some values of percent[ID] return null here!!!
		// console.log("percent[ID]", percent[ID], "percentValue", percentValue, "input", input)
	});
	input = Math.round(input)
	console.log("input after round", input)
	// console.log("progress start", progress)
	 
	// console.log(progress);
	typeof progress == 'undefined' ? console.log("undefined") : (console.log(progress),clearInterval(progress));

	// console.log("start clearInterval(progress)", clearInterval(progress));
	 progress = setInterval(() => {
		if (start < input) {
			start++;
			progressEnd();
			// console.log("start++", start, "input", input)
		} 
		if (start > input){
			start--;
			progressEnd();
			// console.log("start--", start, "input", input)
		}

		function progressEnd() {
			dietPercent.innerHTML = ` ${start}% <br> of daily <br> needs met`;
			conicGradient.style.background = 
							`conic-gradient(
				 			rgba(1, 1, 1, 0) ${start * 3.6}deg,
							rgb(233, 233, 233) 0deg,
							rgb(233, 233, 233) 360deg)`;

			if (start == input) {
				clearInterval(progress);
			}
		}
	}, 50);
// }
}