export default () => {
    const select_charts_cards = document.querySelector(".select_charts_cards")
    
    const barChartDiv = document.getElementById("barChartDiv")
    const nutrient_cards = document.querySelector(".nutrient_cards")

    document.getElementById("charts_btn").addEventListener("click", () => {

        select_charts_cards.style.left = "0";

        charts_btn.classList.add("active")
        cards_btn.classList.remove("active")

        barChartDiv.classList.add("active")
        nutrient_cards.classList.remove("active")
    })

    document.getElementById("cards_btn").addEventListener("click", () => {

        select_charts_cards.style.left = "-84vw";

        cards_btn.classList.add("active")
        charts_btn.classList.remove("active")

        nutrient_cards.classList.add("active")
        barChartDiv.classList.remove("active")
    })
}