// import { removeFood } from "./RemoveFood.js";
import { getFoodValues } from "../routes/GetFoodValues";

// Update div foodselect
// const foodSelection = document.getElementById("foodSelection");
const list_of_foods = document.querySelector(".list_of_foods ul")
// const listDiv = document.getElementById("listDiv");
const foods_number = document.querySelector(".foods_number");
const chartButtons = document.getElementById("chartButtons");
const charts_div = document.getElementById("charts");
const charts_li = document.getElementById("charts_li")
const charts_li_mobile = document.getElementById("charts_li_mobile")
// listDiv.addEventListener("click", () => {
// 	listDiv.classList.toggle("open");
// });

const barChartDiv = document.getElementById("barChartDiv");

export function presentSelectedFoods(foods) {
	// Food list clickable buttons
	// foodSelection.innerHTML = "";
	list_of_foods.innerHTML = "";

	if (!foods) {
		activateStats();
		document.querySelector(".charts_title").classList.add("hidden")
		return;
	}
	
	//console.log("foods in presentSelectedFoods: ", foods);
	Object.keys(foods).forEach((fK, index) => {
		document.querySelector(".charts_title").classList.remove("hidden");
		if (fK[0] != "%") {
			// foodSelection.innerHTML += `<button id=${fK}>${foods[fK].fName}</button>`;
			// console.log(list_of_foods);
			list_of_foods.innerHTML += `
			<li id=${fK}>
			<div>
				<h3>${index + 1}</h3>
				<h3>${foods[fK].fName}</h3>
			</div>
			<div>
				<input type="number" min="0" value="100">
				<h3>g</h3>
			</div>
			</li>`;
			
			//console.log("index: ", index);
			foods_number.innerHTML = index + 1;

			if (window.innerWidth < 769) {
				barChartDiv.style.height = `${index * 5 + 95}vw`;
			} else {
				barChartDiv.style.height = `${index * 0.5 + 30}vw`;
			}
		}
	});
	removeFood(list_of_foods);
	activateStats();
}

const dietScoreBox = document.getElementById("dietScoreBox");
const chartDiv = document.getElementById("chartDiv");

function activateStats() {
	if (list_of_foods.childNodes.length > 0) {
		charts_li.classList.remove("hidden");
		charts_li_mobile.classList.remove("hidden");
		charts_div.classList.add("active");

	} else {
		charts_div.classList.remove("active");
		charts_li.classList.add("hidden");
		charts_li_mobile.classList.add("hidden");
	}
}

// Remove food from selection
export function removeFood(list_of_foods) {
	let urltemp = window.location.pathname;
	list_of_foods.childNodes.forEach(child => {
		child.addEventListener("click", () => {
			urltemp = urltemp.split(".").pop();
			urltemp = urltemp.replace(child.id, "").replace(/&&/g, "&");
			if (urltemp[0] == "&") {
				urltemp = urltemp.slice(1);
			}
			console.log("urltemp", urltemp);
			getFoodValues(urltemp);
		});
	});
}
