const nutrient_cards = document.querySelector(".nutrient_cards");

export default (res, IDs) => {

	let percent = res.Percent
	let names = res.nName
	let ref = res.nRef
	let unit = res.Unit
	let sum	= res.nSum

	nutrient_cards.innerHTML = "";
	IDs.forEach(ID => {
		nutrient_cards.innerHTML += `
		<div class="nutrient" id=${ID}>
			<h1>${names[ID]}</h1>
			<h2 class="hide">Daily needs met: </h2><h2>${percent[ID]}%</h2>
			<h3>Daily recommended value</h3><h3> ${ref[ID]} ${unit[ID]}</h3>
			<h3>Sum all foods</h3><h3>${sum[ID]}g Total</h3>
			<h3>Scientific source</h3><h3>Somelink leading to source</h3>
		</div> `;
	});

	// Working Array from each nutrient
	let activeChild;
	Array.from(nutrient_cards.children).forEach(child => {
		child.addEventListener("click", e => {
			e.currentTarget.classList.toggle("active");
			if (activeChild && activeChild != e.currentTarget) {
				activeChild.classList.remove("active");
			}
			activeChild = e.currentTarget;
		});
	});
}