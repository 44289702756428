export default (search_input, window_width, landing) => {
	let home_li 
	let charts_li
	let sources_li
	let about_us_li
	let privacy_policy_li
	const title = document.getElementById("title");
	const charts_title = document.querySelector(".charts_title")
	if(window_width < 769){
		const nav_mobile_lis = document.querySelectorAll("#nav_mobile ul li")

		home_li = document.getElementById("home_li_mobile");
		charts_li = document.getElementById("charts_li_mobile");
		sources_li = document.getElementById("sources_li_mobile");
		about_us_li = document.getElementById("about_us_li_mobile");
		privacy_policy_li = document.getElementById("privacy_policy_li_mobile");

		document.getElementById("hamburger").addEventListener("click", () => {
			document.getElementById("nav_mobile").classList.toggle("active");	
			document.querySelector("#nav_mobile").classList.contains("active") ? 
			(nav_mobile_lis.forEach((li, index) => {li.style.transitionDelay = `${index*0.2}s`}), 
			privacy_policy_li.style.transitionDelay = `${(nav_mobile_lis.length + 1)*0.2}s`) :
			(nav_mobile_lis.forEach((li) => {li.style.transitionDelay = "0s"}), 
			privacy_policy_li.style.transitionDelay = "0s");
		})

	} else {
		home_li = document.getElementById("home_li");
		charts_li = document.getElementById("charts_li");
		sources_li = document.getElementById("sources_li");
		about_us_li = document.getElementById("about_us_li");
		privacy_policy_li = document.getElementById("privacy_policy_li");

		const main_nav = document.getElementById("nav");
		main_nav.addEventListener("mouseover", () => {
			landing.classList.contains("active")
				? title.classList.add("nav_hover")
				: "";
		});
		main_nav.addEventListener("mouseout", () => {
			landing.classList.contains("active")
				? title.classList.remove("nav_hover")
				: "";
		});
	}
	
	const landing_div = document.querySelector('#landing');
	const charts_div = document.querySelector('#charts');
	const sources_div = document.querySelector('#sources');
	const about_us = document.querySelector('#about_us');
	const privacy_policy = document.querySelector('#privacy_policy');
	
	const navigation_array = [
		{li:home_li, el:landing_div},
		{li:charts_li, el:charts_div},
		{li:sources_li, el:sources_div},
		{li:about_us_li, el:about_us},
		{li:privacy_policy_li, el:privacy_policy},
	]
	
	navigation_array.forEach(item => {
		nav_click(item.el, item.li)
	})
	function nav_click (element, element_li) {
		element_li.addEventListener("click", () => {
			console.log(element, element_li)
			element.scrollIntoView({behavior: 'smooth'});
		})
	}
	// *** Solution to scroll going too far
	// const id = 'profilePhoto';
	// const yOffset = -10; 
	// const element = document.getElementById(id);
	// const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

	// window.scrollTo({top: y, behavior: 'smooth'});

	// Something else
	// charts_title_content.addEventListener("click", () => {
		// 	list_of_foods.classList.toggle("active");
		// });
		
		
	const list_of_foods = document.querySelector(".list_of_foods");
	// Search criteria icon open
	const search_criteria = document.querySelector(".search_criteria");
	const search_criteria_icon = document.querySelector(".search_criteria_icon");

	const foodDropdown = document.getElementById("foodDropdown");
	// const search_input = document.querySelector(".search_input");
	const search_box = document.querySelector(".search_box");
	const search_icon = document.querySelector(".search_icon")

	window.addEventListener("click", e => {
		search_criteria.contains(e.target)?(search_criteria_icon.classList.add("active")):(search_criteria_icon.classList.remove("active"));
		search_input.contains(e.target) ? foodDropdown.classList.add("active") : foodDropdown.classList.remove("active");
		document.querySelector(".charts_title_content").contains(e.target) ? list_of_foods.classList.add("active") : list_of_foods.classList.remove("active");
		let searchValue = search_input.value
		if(window_width < 769){
			if(search_box.contains(e.target)){
				search_box.classList.add("active")
				charts_title.classList.add("search_active")
				if(searchValue !== ""){
					search_icon.classList.remove("reverse")
					search_icon.classList.add("active")
				}
			} else {
				search_box.classList.remove("active")
				charts_title.classList.remove("search_active")
				if(search_icon.classList.contains("active")){
					search_icon.classList.remove("active")
					search_icon.classList.add("reverse")
				}
			}
		}
	})

	// Maybe use intersection observer here
	// Scroll event observing elements
	window.addEventListener("scroll", () => {
		navigation_array.forEach(item => {
			nav_highlight(item.el, item.li)
		})
	})

	const window_half_height = window.innerHeight / 2;
	
	function nav_highlight(element, element_li) {
		let element_position = element.getBoundingClientRect()
		if (element_position.top < window_half_height && element_position.bottom > window_half_height) {
			element_li.classList.add("active")
		} else {
			element_li.classList.remove("active")
		}
	}
}