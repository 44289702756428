export let criteria_string = '';

export default () => {
	const age = document.getElementById("age");
	const gender = document.getElementsByName("gender");
	const weight = document.getElementById("weight");
	const pa = document.getElementById("pa");
	const paDesc = document.getElementById("paDesc");
	const categoryShort = document.getElementById("categoryShort");

	// Get criteria to be passed to presentFood()
	const criteria = {};
	console.log("Criteria.js called")
	criteria.age = age.value;
	age.addEventListener("change", (e) => {
		console.log("age e", e.target.value)
		console.log("testing change")
		criteria.age = age.value;
		return buildCriteria();
	});
	
	for (let i = 0; i < gender.length; i++) {
		if (gender[i].checked) {
			criteria.gender = gender[i].value;
		}
	}
	gender.forEach(gen => {
		gen.addEventListener("change", () => {
			console.log("gen", gen.value)
			criteria.gender = gen.value;
			return buildCriteria();
		});
	});
	
	criteria.weight = weight.value;
	weight.addEventListener("change", () => {
		criteria.weight = weight.value;
		return buildCriteria();
	});
	
	let paDescriptor = pa.value;
	criteria.pa = pa.value;
	pa.oninput = () => {
		criteria.pa = pa.value;
		// console.log(criteria);
		switch (pa.value) {
			case "1":
				paDescriptor = "Low";
				paDesc.innerHTML = paDescriptor;
				break;

			case "2":
				paDescriptor = "Moderate-Low";
				paDesc.innerHTML = paDescriptor;
				break;

			case "3":
				paDescriptor = "Moderate";
				paDesc.innerHTML = paDescriptor;
				break;
			case "4":
				paDescriptor = "Moderate-High";
				paDesc.innerHTML = paDescriptor;
				break;

			case "5":
				paDescriptor = "High";
				paDesc.innerHTML = paDescriptor;
				break;
				
			default:
				console.log("Physical activity codeblock error");
				// console.log("paDesc", paDesc, "pa.value", pa.value);
		}
		return buildCriteria();
	};
	
	const db = "sf";
	criteria.db = db;
	
	// const settings = document.querySelector(".settings");
	// const categorySelection = document.getElementById("categorySelection");
	
	return buildCriteria();
	
	function buildCriteria() {
		criteria_string = criteria.age.concat(
			".",
			criteria.gender,
			".",
			criteria.weight,
			".",
			criteria.pa,
			".",
			criteria.db,
			"."
		);
	
		categoryShort.innerHTML = `Age: ${criteria.age}, Gender: ${criteria.gender}, weight: ${criteria.weight}kg, Physical Activity: ${paDescriptor}`;

		return criteria_string;
	}

	function applyCriteria() {
		//btn onlick send server request
	}
}
