const sources = document.getElementById("sources")
const source_tables_wrapper_children = document.querySelectorAll(".source_tables_wrapper .source_table")
// console.log("source_tables_wrapper", source_tables_wrapper)

const macro_sources = document.getElementById("macro_sources")
const vitamins_sources = document.getElementById("vitamins_sources")
const minerals_sources = document.getElementById("minerals_sources")
const energy_sources = document.getElementById("energy_sources")

const macro_sources_content = macro_sources.querySelector(".source_table_content")
const vitamins_sources_content = vitamins_sources.querySelector(".source_table_content")
const minerals_sources_content = minerals_sources.querySelector(".source_table_content")
const energy_sources_content = energy_sources.querySelector(".source_table_content")

const macro_sources_li = document.getElementById("macro_sources_li")
const minerals_sources_li = document.getElementById("minerals_sources_li")
const vitamins_sources_li = document.getElementById("vitamins_sources_li")
const energy_sources_li = document.getElementById("energy_sources_li")

export default (res) => {
    // console.log("res at present sources", res.energy)
    sources.classList.add("active")
    // Handle server response & populate data Vitamins, Minerals, Macro
    let vitamins_arr = [];
    let minerals_arr = [];
    let macro_arr = [];

    // console.log("res.sources.source", res.sources.source)
    for (let subkey in res.sources.source){
        // console.log(subkey, res.sources.source[subkey])
        res.sources.source[subkey] == "vitamins" ? vitamins_arr.push(subkey) : 
        res.sources.source[subkey] == "minerals" ? minerals_arr.push(subkey) :
        res.sources.source[subkey] == "macronutrients" ? macro_arr.push(subkey) : "";
    }
    // console.log(vitamins_arr, "el", minerals_arr)
    for (let key in res.sources) {
        if (key != "source"){
            vitamins_sources_content.innerHTML += `<h2>${key != "name" ? key : ""}</h2>`
            minerals_sources_content.innerHTML += `<h2>${key != "name" ? key : ""}</h2>`
            macro_sources_content.innerHTML += `<h2>${key != "name" ? key : ""}</h2>`

            populate_sources_charts(macro_sources_content, macro_arr, key)
            populate_sources_charts(vitamins_sources_content, vitamins_arr, key)
            populate_sources_charts(minerals_sources_content, minerals_arr, key)

        }
    }

    macro_sources_content.style.gridTemplateColumns = `repeat(${macro_arr.length +1}, 1fr)`;
    vitamins_sources_content.style.gridTemplateColumns = `repeat(${vitamins_arr.length +1}, 1fr)`;
    minerals_sources_content.style.gridTemplateColumns = `repeat(${minerals_arr.length +1}, 1fr)`;

    function populate_sources_charts(element, array, key){
        array.forEach(item => {
            element.innerHTML += `${key == "name" ? "<h3>" : "<h4>"}${res.sources[key][item]}${key != "name" ? "</h3>" : "</h4>"}`
        })
    }
    // Handle server response & populate data Energy
    res.energy.forEach(entry => {
        // console.log(entry)
        energy_sources_content.innerHTML += `<h2>${entry.nkey}</h2><h4>${entry[1008]}</h4>`
        energy_sources_content.style.gridTemplateColumns = `repeat(2, 1fr)`;

    })

    // Build swiper
    let tables_array = [macro_sources, minerals_sources, vitamins_sources, energy_sources]
    let li_array = [macro_sources_li,  minerals_sources_li, vitamins_sources_li, energy_sources_li]

    tables_array.forEach((table, index) => {
        table.dataset.swiper = index;
        table.style.left = `${index * 120}%`;
    });
    
    li_array.forEach((li, index) => {
        li.dataset.swiper = index;
        li.addEventListener("click", () => {
            li_array.forEach(sub_li => {
                sub_li.classList.remove("active")
            })
            li.classList.add("active")
            source_tables_wrapper_children.forEach(child => {
                child.style.left = `${(child.dataset.swiper - index) * 120}%`;
                child.dataset.swiper == li.dataset.swiper ? 
                (child.classList.add("active"), Foggy(child)) : 
                child.classList.remove("active");
            })
        })
    });
    let horizontal;
    const fog_left = document.querySelector(".fog_left")
    const fog_right = document.querySelector(".fog_right")
    Foggy(macro_sources)
    function Foggy(el){
        if(window.innerWidth < 1151){
            let compare_width = el.querySelector(".source_table_content").offsetWidth - el.offsetWidth
            if (compare_width < 0){
                fog_left.classList.remove("active")
                fog_right.classList.remove("active")
            } else {
                horizontal = el.scrollLeft;
                Compare_Foggy(horizontal, compare_width)
                el.addEventListener("scroll", () => {
                horizontal = el.scrollLeft;
                Compare_Foggy(horizontal, compare_width)
            });
            }
            function Compare_Foggy(horizontal, compare_width){
                if(horizontal >= compare_width){
                    fog_left.classList.add("active")
                    fog_right.classList.remove("active")
                } else if(horizontal == 0){
                    fog_left.classList.remove("active")
                    fog_right.classList.add("active")
                }  else {
                    fog_left.classList.add("active")
                    fog_right.classList.add("active")
                }
            }
        }
    }
}