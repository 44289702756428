export default (landing) => {
	const charts_title = document.querySelector(".charts_title")
	const search = document.querySelector(".search");
	const body_element = document.getElementById("body_div");
	
	check(charts_title)
	check(landing)
	check(search)

	let background_position = 0;
	
	window.onscroll = function () {
		this.oldScroll > this.scrollY
			? (background_position = background_position + 3)
			: (background_position = background_position - 3);
		this.oldScroll = this.scrollY;
		body_element.style.backgroundPosition = `0px ${background_position}px`;
		// scroll_show(this.scrollY);
	};

	function check(el){
		el.getBoundingClientRect().top < 0 ? el.classList.add("active"):'';
	}
	const observer = new IntersectionObserver( 
	  ([e]) => 
	  e.target.classList.toggle("active", e.intersectionRatio < 1),
	  { threshold: [1] }
	);

	observer.observe(charts_title);
	observer.observe(search);
	observer.observe(landing);

}
const element = document.getElementById("landing");
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    console.log(entry.intersectionRatio);
  });
}, { threshold: [0, 0.25, 0.5, 0.75, 1] });

observer.observe(element);