import Chart from 'chart.js/auto';

// IDs for each chart type

const mainChartIds = [1003, 1004, 1005, 1051, 1008];
const minChartIds = [1087, 1089, 1090, 1091, 1092, 1093, 1095, 1098, 1103];
const vitChartIdsOld = [
	1105, 1106, 1107, 1108, 1109, 1114, 1120, 1122, 1123, 1162, 1165, 1166, 1167,
	1175, 1177, 1178, 1180, 1185, 1186, 1187, 1190, 1242, 1246,
];
const vitChartIds = [
	1106, 1165, 1166, 1167, 1175, 1190, 1178, 1162, 1109, 1185,
];

// Chart settings and styles
const optionsBar = {
	maintainAspectRatio: false,
	responsive: true,
	scales: {
		x: {
			stacked: true,
		},
		y: {
			beginAtZero: true,
			stacked: true,
			ticks: {
				callback: function (value, index, values) {
					return this.getLabelForValue(value) + "%";
				},
			},
		},
	},
	plugins: {
		tooltip: {
			callbacks: {},
		},
		// title: {
		// 	display: true,
		// 	text: "Average daily requirements by food group",
		// },
	},
};

const barColor = [
	"rgba(211, 0, 0, 0.5)",
	"rgba(204, 204, 0, 0.5)",
	"gray",
	"rgba(33,212,252, 0.5)",
	"rgba(255,79,0, 0.5)",
];

const barColorHover = [
	"rgba(211, 0, 0, 1)",
	"rgba(204, 204, 0, 1)",
	"gray",
	"rgba(33,212,252, 1)",
	"rgba(255,79,0, 1)",
];
const mainChart = new Chart(mainChartID, {
	type: "bar",
	data: {},
	options: optionsBar,
});

// 1178 natural B12, 1246 added B12
// 1109 natural E, 1242 added E
// 1114 D2 + D3


// Chart building functionality
// Chart function is attempted to be accessed on pageLoad, before Chart initilization
export default (res, IDs) => {
	buildBarChart(res, IDs)
	function buildBarChart(res, IDs) {
		let chartLabel = [];
		let chartData = [];
		let datasetsArr = [];
		if (!res) {
			mainChart.data.datasets = datasetsArr;
			return;
		}
		Object.keys(res.foods).forEach(food => {
			if (food[0] == "%") {
				for (let ID of IDs) {
					if (chartLabel.length < IDs.length) {
						chartLabel.push(res.nName[ID]);
					}
					let foodVal = res.foods[food][ID] ? res.foods[food][ID].match(/\d+\.?\d*/) : 0;
					foodVal = parseFloat(foodVal[0]);
					chartData.push(foodVal);
				}
				datasetsArr.push({
					data: chartData,
					label: res.foods[food].fName,
					backgroundColor: barColor,
					hoverBackgroundColor: barColorHover,
				});
			}
			mainChart.data.datasets = datasetsArr;
			chartData = [];
		});
		mainChart.data.labels = chartLabel;

		mainChart.update();
	}
}