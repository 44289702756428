import { getFoodValues } from "./GetFoodValues.js";
//import { presentSelectedFoods } from "./PresentSelectedFoods.js";

// Get user input search food => CreateDropdown()

// const foodSearchVar = document.getElementById("foodSearch");
// foodSearchVar.addEventListener("input", foodSearchFunc);

const serverURL = "https://www.nutristats.net/";

// serverurl should be imported from global variable
const foodDropdown = document.getElementById("foodDropdown");
// const searchInput = document.getElementById("foodSearch");
const search_input = document.querySelector(".search_input");
const search_icon = document.querySelector(".search_icon");

let searchValue;
let scroll_timeout;

foodDropdown.addEventListener("scroll", function () {
	console.log("scolled")

	clearTimeout(scroll_timeout);
	foodDropdown.classList.add("scrolled")

	scroll_timeout = setTimeout(() => {
		foodDropdown.classList.remove("scrolled")
	  }, 1000);
})

search_input.addEventListener("input", foodSearchFunc);

function foodSearchFunc() {
	//console.log("searchValue", searchValue);
	searchValue = search_input.value;
	if (searchValue.charAt(0) === ' ') {
		console.log("space not allowed as first character")
		search_input.value = "";
	} else if (!searchValue) {
		//console.log("Empty value");
		CreateDropdown("");
		search_icon.classList.remove("active");
		search_icon.classList.add("reverse");
		// search_icon.removeEventListener("click")
	} else {
		search_icon.classList.add("active");
		search_icon.classList.remove("reverse");
		search_icon.addEventListener("click", ()=> {
			search_input.value = '';
			foodSearchFunc();
		})
		getFoods(searchValue);
	}
}

const foodUrl = "foods/";
// Request express foods database
async function getFoods(searchValue) {
	let res = await fetch(serverURL + foodUrl + searchValue, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then(response => {
			if (!response.ok) {
				throw new Error("HTTP status " + response.status);
			}
			return response.json();
		})
		.then(res => {
			console.log("res at CreateDropdown", res)
			CreateDropdown(res);
		})
		.catch(error => {
			console.log(
				error
			); /* ...*** handle/report error, since this code doesn't return the promise chain...*/
		});
}

// Create dropdown from searched food => result=API
function CreateDropdown(res) {
	foodDropdown.innerHTML = "";
	if(res.length > 0){
		foodDropdown.classList.remove("not-found")
		res.forEach(item =>{
			foodDropdown.innerHTML += `<button data-food=${item.fId}>${item.name}</button>`;
		})

		let foodChildren = foodDropdown.childNodes;
		Array.from(foodChildren).forEach(child => {
			child.addEventListener("click", e => {
				let urltemp;
				window.location.pathname.includes("result")
					? (urltemp = window.location.pathname
							.replace("/result/", "")
							.replace("&&", "&")
							.concat("&", e.target.dataset.food))
					: (urltemp = e.target.dataset.food);
				getFoodValues(urltemp);
			});
		});
	} else {
		foodDropdown.classList.add("not-found")
		foodDropdown.innerHTML += "<button>No results found</button>";
	}
}
