import DietScore from "./DietScore";
import build_cards from "./build_cards";
import BuildCharts from "./BuildCharts";

// import { getFoodValues } from "../../routes/GetFoodValues";

// let res = getFoodValues();

const nutrient_selection = require("./nutrients.json")
const test_array = 
    [1003, 1004, 1005, 1008, 1051, 1087, 1089, 1090, 1091, 1092, 1093, 1095,
    1098, 1103, 1105, 1106, 1107, 1108, 1109, 1114, 1120, 1122, 1123, 1162,
    1165, 1166, 1167, 1175, 1177, 1178, 1180, 1185, 1186, 1187, 1190, 1242,
    1246, 1253, 1258, 1259, 1260, 1261, 1262, 1263, 1264, 1265, 1266, 1268,
    1269, 1270, 1271, 1272, 1275, 1276, 1277, 1278, 1279, 1280, 1292, 1293];
    

const nutrients_list = document.querySelectorAll(".choose_nutrients ul li")
// console.log("nutrients_list", nutrients_list)
const all_btn = document.getElementById("all_btn");
const macro_btn = document.getElementById("macro_btn");
const vitamins_btn = document.getElementById("vitamins_btn");
const minerals_btn = document.getElementById("minerals_btn");
const lipids_btn = document.getElementById("lipids_btn");
const amino_acids_btn = document.getElementById("amino_acids_btn");
const others_btn = document.getElementById("others_btn");

let current_selection;

function callStats(res, IDs) {
    BuildCharts(res, IDs);
    build_cards(res, IDs);
    DietScore(res, IDs)
}

export default (res) => {
    callStats(res.resultObj, (current_selection ? current_selection : nutrient_selection.all))
    nutrients_list.forEach(item => {
        item.addEventListener("click", () => {
            nutrients_list.forEach(item => {
                item.classList.remove("active")
            })
            item.classList.add("active")
            switch (item) {
                case all_btn: current_selection = nutrient_selection.all; break;
                case macro_btn: current_selection = nutrient_selection.macro; break;
                case vitamins_btn: current_selection = nutrient_selection.vitamins; break;
                case minerals_btn: current_selection = nutrient_selection.minerals; break;
                case lipids_btn: current_selection = nutrient_selection.lipids; break;
                case amino_acids_btn: current_selection = nutrient_selection.amino_acids; break;
                case others_btn: current_selection = nutrient_selection.others; break;
                default: console.log("Default error with buttons"); break;
            }
            callStats(res.resultObj, current_selection);
        })
    })
}