import "../frontend/css/main.scss";
import index from "../frontend/js/index"

window.addEventListener("load", () => {
	const loader_wrapper = document.querySelector(".loader-wrapper")
	loader_wrapper.classList.add("hidden");
})

const main = async (err) => {
	if (err) {console.error(err);}
	index();
}


window.addEventListener("DOMContentLoaded", () => {
	main();
})

// window.addEventListener("DOMContentLoaded", () => {

// });
