import { criteria_string } from "../components/Criteria";
import { presentSelectedFoods } from "../components/foods_list";
import buildBarChart from "../components/stats/BuildCharts";
import dietScore  from "../components/stats/DietScore";
import filter_nutrients from "../components/stats/filter_nutrients";

const serverURL = "https://nutristats.net/";

const mainChartIds = [1003, 1004, 1005, 1051, 1008];

// export let ress = {};
// Present data upon selection + URL change for sharing
export async function getFoodValues(desc) {
	console.log("criteria_string in getfoodval", criteria_string)
	if (!desc) {
		window.history.replaceState(null, null, "");
		presentSelectedFoods();
		// dietScore();
		// buildBarChart();
	} else {
		desc = criteria_string + desc;
		let tempArray = desc.split(".");
		desc = criteria_string + tempArray[tempArray.length - 1];

		const searchUrl = "result/";
		let res = await fetch(serverURL + searchUrl + desc, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then(response => {
				if (!desc) {
					window.history.replaceState(null, null, "/");
					console.log("null state pushed");
				} else if (!response.ok) {
					throw new Error("HTTP status " + response.status + { response });
				}
				return response.json();
			})
			.then(res => {
				console.log("res at GetFoodValues", res)
				window.history.replaceState(null, null, searchUrl + desc);
				presentSelectedFoods(res.resultObj.foods);
				// dietScore(
				// 	res.resultObj.Percent,
				// 	res.resultObj.nName,
				// 	res.resultObj.nRef,
				// 	res.resultObj.Unit,
				// 	res.resultObj.nSum
				// );
				// buildBarChart(res.resultObj, mainChartIds);
				filter_nutrients(res);
			})
			.catch(error => {
				console.log(error);
			});
	}
}
