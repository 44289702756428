import main_nav from "./layout/main_nav";
import animate_sections from "./layout/animate_sections";

import Criteria from "./components/Criteria";
// import DietScore from "./components/DietScore";
import switch_charts_cards from "./layout/switch_charts_cards";
// import filter_nutrients from "./components/stats/filter_nutrients";
// import build_cards from "./components/stats/build_cards";

import { foodSearchFunc } from "./routes/GetFoodsList.js";
import { getFoodValues } from "./routes/GetFoodValues.js";
import get_sources from "./routes/get_sources";

export default () => {
    // Remove initial warning popup
    document.getElementById("warning_button").addEventListener("click", RemoveWarning)
    function RemoveWarning() {
        document.querySelector(".warning").classList.remove("active")
        document.querySelector("html").classList.remove("no-scroll")
        document.getElementById("warning_button").removeEventListener("click", RemoveWarning)
        // getEventListeners(document.querySelector("#warning_button"));
    }
    
    const search_input = document.querySelector(".search_input");
	const landing = document.getElementById("landing");
    const window_width = window.innerWidth;
    

    main_nav(search_input, window_width, landing);
    animate_sections(landing);
    Criteria()
    // console.log("Criteria()",Criteria())
    // DietScore();
    switch_charts_cards();
    // filter_nutrients();
    // build_cards();
    get_sources();
	// Check current URL if foods are selected
    let currentUrl = window.location.href;
	if (currentUrl.includes("result/")) {
		let temp = currentUrl.split("result/");
		temp.shift();
		getFoodValues(temp);
	}
    // Implement IntersectionObserver js function!!!

}