import present_sources from "../components/present_sources";

export default () => {
    console.log("routes/get_sources called")
    const serverURL = "https://www.nutristats.net/";

    const sources_Url = "sources/";
    // Request express foods database
    getSources()
    async function getSources() {
        let res = await fetch(serverURL + sources_Url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("HTTP status " + response.status);
                }
                return response.json();
            })
            .then(res => {
                // console.log("sources path", res)
                present_sources(res)
            })
            .catch(error => {
                console.log(
                    error
                ); /* ...*** handle/report error, since this code doesn't return the promise chain...*/
            });
    } 
}